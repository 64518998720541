import { Namespace, TFunction } from 'i18next';
import { Option } from '../../../../components/Option';

export enum SelectionMode {
  Mutltiple,
  Single,
}

export const SelectionModeKeys = {
  [SelectionMode.Mutltiple]: 'common:selection-mode.multiple',
  [SelectionMode.Single]: 'common:selection-mode.single',
} as const;

export const selectionModeOptions = (t: TFunction<Namespace>): Option<string, string | SelectionMode>[] => {
  return [
    {
      id: SelectionMode.Mutltiple.toString(),
      text: t(SelectionModeKeys[SelectionMode.Mutltiple]),
      value: SelectionMode.Mutltiple,
    },
    {
      id: SelectionMode.Single.toString(),
      text: t(SelectionModeKeys[SelectionMode.Single]),
      value: SelectionMode.Single,
    },
  ];
};
